<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs">
            <v-layout align-stretch
                      :wrap="isMobile">
                <v-flex class="px-4" sm6>
                    <TextField :label="$t('labels.inspection_name')"
                               name="name"
                               rules="required"
                               v-model="name"></TextField>
                    <Autocomplete :label="$t('labels.customer')"
                                  name="client_id"
                                  rules="required"
                                  clearable
                                  @click:clear="getCustomers(null, null, true)"
                                  @load-more-items="getCustomersLazyLoading(true)"
                                  :items="listingCustomers"
                                  :loading="isCustomersLoading"
                                  :search-input.sync="searchCustomers"
                                  v-model="clientId"></Autocomplete>
                    <Autocomplete :label="$t('labels.site')"
                                  name="location_id"
                                  rules="required"
                                  :items="sites"
                                  clearable
                                  @click:clear="getSites(null, null, clientId)"
                                  @load-more-items="getSitesLazyLoading(clientId)"
                                  :loading="isSitesLoading"
                                  :search-input.sync="searchSitesPerCustomer"
                                  :disabled="!clientId"
                                  :success-messages="showSiteSuccessMessage"
                                  v-model="locationId"></Autocomplete>
                    <Autocomplete :label="$t('labels.location')"
                                  name="point_id"
                                  rules="required"
                                  clearable
                                  @click:clear="getLocations(null, null, locationId)"
                                  @load-more-items="getLocationsLazyLoading(locationId)"
                                  :items="locations"
                                  :loading="isLocationsLoading"
                                  :search-input.sync="searchLocationsPerSite"
                                  :disabled="!locationId"
                                  :success-messages="showLocationSuccessMessage"
                                  v-model="pointId"></Autocomplete>
                    <Autocomplete :label="$t('labels.template')"
                                  :no-data-text="$t('support.no_templates_for_point')"
                                  name="template_id"
                                  rules="required"
                                  clearable
                                  @click:clear="getTemplates(null, templatesFilteringParams)"
                                  @load-more-items="getTemplatesLazyLoading(null, templatesFilteringParams)"
                                  :items="filterableTemplates"
                                  :loading="isTemplatesLoading"
                                  :search-input.sync="searchTemplates"
                                  :disabled="!pointId"
                                  :success-messages="showTemplatesSuccessMessage"
                                  v-model="templateId"></Autocomplete>
                    <Autocomplete :label="$t('labels.responsible_person')"
                                  :items="responsibleEmployees"
                                  rules="required"
                                  clearable
                                  :loading="isResponsibleEmployeesLoading"
                                  :search-input.sync="searchResponsibleEmployees"
                                  @click:clear="getResponsibleEmployees"
                                  @load-more-items="getResponsibleEmployeesLazyLoading"
                                  name="employee_id"
                                  v-model="employeeId"></Autocomplete>
                    <SelectField :label="$t('labels.notification_time')"
                                 name="notification_time"
                                 rules="required"
                                 :items="timeOptions"
                                 v-model="notificationTime"></SelectField>
                </v-flex>
                <v-flex class="px-4" sm6>
                    <DatePicker :label="$t('labels.start_date')"
                                            name="start_date"
                                            :rules="isEditable? '' : 'isDateGreaterOrEqualNow|required'"
                                            :clearable="false"
                                            :disabled="isEditable"
                                            v-model="date"></DatePicker>
                    <TimePicker :label="$t('labels.planned_start_time')"
                                name="time"
                                :rules="isEditable ? 'required' : 'isTimeGreaterThanNow:@start_date|required'"
                                use-seconds
                                v-model="time"></TimePicker>
                    <TextField :label="$t('labels.tolerance')"
                               name="time_tolerance"
                               :suffix="$t('labels.minutes')"
                               type="number"
                               rules="required"
                               min="0"
                               max="1440"
                               v-model="timeTolerance"></TextField>
                    <SelectField :label="$t('labels.cycle')"
                                 name="cycle"
                                 clearable
                                 :rules="isEditable? '' : 'required'"
                                 :disabled="isEditable"
                                 :items="cycleOptions"
                                 v-model="cycle"></SelectField>
                    <Autocomplete :label="$t('labels.incident')"
                                  name="incidentTypeId"
                                  rules="required"
                                  clearable
                                  @click:clear="getIncidentsTypes(null)"
                                  @load-more-items="getIncidentsTypesLazyLoading(null)"
                                  :items="filterableTypesList"
                                  :loading="isTypesLoading"
                                  :search-input.sync="searchTypes"
                                  v-model="incidentTypeId"></Autocomplete>
                </v-flex>
            </v-layout>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import { EVENT_CYCLES } from '@/constants/eventCycles';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import DatePicker from '@/components/widgets/forms/DatePicker';
import TimePicker from '@/components/widgets/forms/TimePicker';
import TextField from '@/components/widgets/forms/TextField';
import SelectField from '@/components/widgets/forms/SelectField';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';

export default {
    name: 'ManageEventForm',
    components: { ValidationObserver, Autocomplete, DatePicker, TimePicker, SelectField, TextField },
    mixins: [debouncedCustomers, debouncedSites, debouncedLocations, debouncedTemplates, debouncedResponsibleEmployee, debouncedIncidentTypes],
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        },
        chosenDate: {
            type: [Date, String],
            'default': ''
        }
    },
    data: () => ({
        until: null,
        inspectionsNumber: null,
        interval: 1,
        showUntilField: false
    }),
    computed: {
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapGetters('sites', [
            'sites'
        ]),
        ...mapGetters('locations', [
            'locations'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ]),
        ...mapState('scheduling', [
            'manageEventFormVisibility'
        ]),
        ...mapGetters('employees', [
            'responsibleEmployees'
        ]),
        ...mapState('employees', [
            'totalResponsibleEmployeesAmount'
        ]),
        ...mapFields('scheduling', [
            'eventData.name',
            'eventData.clientId',
            'eventData.pointId',
            'eventData.locationId',
            'eventData.templateId',
            'eventData.employeeId',
            'eventData.date',
            'eventData.notificationTime',
            'eventData.time',
            'eventData.timeTolerance',
            'eventData.cycle',
            'eventData.incidentTypeId'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        showSiteSuccessMessage () {
            return this.clientId ? '' : this.$t('messages.choose_customer');
        },
        showLocationSuccessMessage () {
            return this.pointId ? '' : this.$t('messages.choose_site');
        },
        showTemplatesSuccessMessage () {
            return this.locationId ? '' : this.$t('messages.choose_location');
        },
        templatesFilteringParams () {
            return {
                'point': this.pointId
            };
        },
        timeOptions () {
            return [
                {
                    text: `0 ${this.$t('labels.minutes')}`,
                    value: 0
                },
                {
                    text: `15 ${this.$t('labels.minutes')}`,
                    value: 15
                },
                {
                    text: `30 ${this.$t('labels.minutes')}`,
                    value: 30
                },
                {
                    text: `1 ${this.$t('labels.hour')}`,
                    value: 60
                },
                {
                    text: `2 ${this.$t('labels.hours')}`,
                    value: 120
                }
            ];
        },
        cycleOptions () {
            return [
                {
                    text: this.$t('scheduling.rrules.year'),
                    value: EVENT_CYCLES.YEARLY
                },
                {
                    text: this.$t('scheduling.rrules.month'),
                    value: EVENT_CYCLES.MONTHLY
                },
                {
                    text: this.$t('scheduling.rrules.week'),
                    value: EVENT_CYCLES.WEEKLY
                },
                {
                    text: this.$t('scheduling.rrules.day'),
                    value: EVENT_CYCLES.DAILY
                },
                {
                    text: this.$t('scheduling.rrules.once'),
                    value: EVENT_CYCLES.ONCE
                }
            ];
        }
    },
    watch: {
        manageEventFormVisibility: {
            handler (val) {
                if (val) {
                    if (this.isEditable) {
                        this.getCustomers(null, this.clientId, true);
                        this.getResponsibleEmployees({
                            'filter[id]': this.employeeId
                        });
                        this.getSites(null, this.locationId);
                        this.getLocations(null, this.pointId);
                        this.getTemplates(null, {
                            'filter[id]': this.templateId
                        });
                        this.getIncidentsTypes(null, this.incidentTypeId);
                    } else {
                        this.date = this.chosenDate;
                        this.getCustomers(null, null, true);
                        this.getResponsibleEmployees();
                        this.getIncidentsTypes(null, null);
                    }
                }
            },
            immediate: true
        },
        clientId: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getSites(null, null, newVal);
                }
                if (!newVal) {
                    this.pointId = null;
                    this.locationId = null;
                    this.templateId = null;
                }
            }
        },
        locationId: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getLocations(null, null, newVal);
                }
                if (!newVal) {
                    this.pointId = null;
                    this.templateId = null;
                }
            }
        },
        pointId: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getTemplates(null, this.templatesFilteringParams);
                }
                if (!newVal) {
                    this.templateId = null;
                }
            }
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
