<template>
    <Modal :modal-title="activeEvent.name"
           :dialog="eventDetailsVisibility"
           max-width="1000px"
           data-test="modal"
           @close="close"
           @visibility="visibility($event)">
        <template slot="title">
            <v-layout align-center>
                <span class="headline">{{ activeEvent.name }}</span>
            </v-layout>
        </template>
        <template slot="content">
            <h3 class="font-weight-medium mb-3">{{ $t('labels.details') }}</h3>
            <v-sheet color="white"
                     class="pa-4"
                     elevation="1">
                <v-layout align-stretch
                          :wrap="isMobile">
                    <v-flex sm6
                            :class="{'bordered pr-4': smAndUp}">
                        <InformationItem :title="$t('labels.customer')"
                                         name="customer"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.clientName"></InformationItem>
                        <InformationItem :title="$t('labels.site')"
                                         name="site"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.locationName"></InformationItem>
                        <InformationItem :title="$t('labels.location')"
                                         name="location"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.pointName"></InformationItem>
                        <InformationItem :title="$t('labels.template')"
                                         name="template"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.templateName"></InformationItem>
                        <InformationItem :title="$t('labels.responsible_person')"
                                         name="responsible_person"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.employeeName"></InformationItem>
                        <InformationItem :title="$t('labels.notification_time')"
                                         name="notification_time"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="notificationTime"></InformationItem>
                    </v-flex>
                    <v-flex sm6
                            :class="{'bordered pl-4': smAndUp}">
                        <InformationItem :title="$t('labels.planned_start_date')"
                                         name="start_date"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="formatDateText(activeEvent.date, activeEvent.time)"></InformationItem>
                        <InformationItem :title="$t('labels.tolerance')"
                                         name="time_tolerance"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="timeTolerance"></InformationItem>

                        <InformationItem :title="$t('labels.cycle')"
                                         name="cycle"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="cycleLabel"></InformationItem>

                        <InformationItem :title="$t('labels.incident')"
                                         name="incidentTypeId"
                                         :title-size="titleSize"
                                         :text-size="textSize"
                                         @edit="showEditForm()"
                                         :text="activeEvent.incidentTypeName"></InformationItem>
                    </v-flex>
                </v-layout>
                <v-layout justify-end class="mt-8">
                    <v-btn color="secondary"
                           outlined
                           class="mr-3"
                           data-test="closeBtn"
                           @click="close">{{ $t('buttons.cancel') }}</v-btn>
                    <v-btn color="secondary"
                           data-test="editBtn"
                           v-if="this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INSPECTION_SCHEDULE)"
                           @click="showEditForm">{{ $t('buttons.edit') }}</v-btn>
                    <ButtonWithConfirmation class="ml-3"
                                            v-if="this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INSPECTION_SCHEDULE)"
                                            @action-executed="handleDelete"
                                            :callback="deleteEvent"
                                            :item-id="activeEvent.id">
                        <v-btn color="secondary">{{ $t('buttons.delete') }}</v-btn>
                    </ButtonWithConfirmation>
                </v-layout>
            </v-sheet>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import InformationItem from '@/components/customers/InformationItem';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import { EVENT_CYCLES } from '@/constants/eventCycles';

export default {
    name: 'EventDetails',
    components: { Modal, InformationItem, ButtonWithConfirmation },
    computed: {
        ...mapState('scheduling', [
            'activeEvent',
            'eventDetailsVisibility'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        smAndUp () {
            return this.$vuetify.breakpoint.smAndUp;
        },
        cycleLabel () {
            if (this.activeEvent.cycle) {
                return EVENT_CYCLES[this.activeEvent.cycle] ? EVENT_CYCLES[this.activeEvent.cycle] : this.activeEvent.cycle;
            }

            return '';
        },
        notificationTime () {
            if (this.activeEvent.notificationTime) {
                switch (this.activeEvent.notificationTime) {
                case 0:
                    return `0 ${this.$t('labels.minutes')}`;
                case 15:
                    return `15 ${this.$t('labels.minutes')}`;
                case 30:
                    return `30 ${this.$t('labels.minutes')}`;
                case 60:
                    return `1 ${this.$t('labels.hour')}`;
                case 120:
                    return `2 ${this.$t('labels.hours')}`;
                default:
                    return `${this.activeEvent.notificationTime} ${this.$t('labels.minutes')}`;
                }
            } else {
                return '';
            }
        },
        timeTolerance () {
            return this.activeEvent.timeTolerance ? `${this.activeEvent.timeTolerance} ${this.$t('labels.minutes')}` : '';
        },
        titleSize () {
            return {
                md5: true,
                sm5: true,
                xs5: true
            };
        },
        textSize () {
            return {
                md7: true,
                sm7: true,
                xs7: true
            };
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$store.commit('scheduling/SET_EVENT_DETAILS_VISIBILITY', event);
        },
        formatDateText (date, time) {
            const _date = date || '';
            const _time = time || '';

            return `${_date} ${_time}`;
        },
        showEditForm () {
            this.$store.commit('scheduling/SET_EVENT_DATA', this.activeEvent);
            this.$store.commit('scheduling/SET_MANAGE_EVENT_FORM_VISIBILITY', true);
        },
        deleteEvent (id) {
            return this.$store.dispatch('scheduling/deleteEvent', id);
        },
        handleDelete () {
            this.$emit('fetch');
            this.close();
        }
    }
};
</script>

<style scoped>

</style>
