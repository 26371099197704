<template>
    <div>

        <v-data-table :headers="headers"
                      disable-sort
                      v-model="selected"
                      :items="events"
                      disable-pagination
                      hide-default-footer
                      item-key="id"
                      @dblclick:row="showEventClick"
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                @sort="sort($event)"
                                :key="i"></SortableHeader>
            </template>
            <template v-slot:item.cycle="{ item }">
                {{ cycleLabel(item.cycle) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon :icon="ICONS.DETAILS"
                    :tooltip="$t('actions.view')"
                    class-name="mr-2"
                    data-test="handleViewIcon"
                    @click="showEventClick(item)"></HintingIcon>

                <HintingIcon :icon="ICONS.EDIT"
                                v-if="canEdit"
                                :tooltip="$t('actions.edit')"
                                @click="editEventClick(item)"
                                data-test="showEditFormIcon"
                                class-name="mr-2"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                    v-if="canRemove"
                    :callback="removeEventClick"></ButtonWithConfirmation>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import { ICONS } from '@/constants/icons';
import HintingIcon from '@/components/widgets/HintingIcon';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import { EVENT_CYCLES } from '@/constants/eventCycles';
import Pagination from '@/components/widgets/tables/Pagination';

export default {
    name: 'Calendar',
    components: { SortableHeader, HintingIcon, ButtonWithConfirmation, Pagination },
    props: {
        pageNumber: {
            type: Number,
            'default': false
        }
    },
    data: () => ({
        selected: [],
        ICONS,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc'
        }
    }),
    watch: {
        pageNumber: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        totalEventsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        }
    },
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ]),
        ...mapState('scheduling', [
            'events',
            'totalEventsAmount'
        ]),
        headers () {
            return [
                {
                    text: this.$t('labels.inspection_name'),
                    value: 'name',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.cycle'),
                    value: 'cycle',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.date'),
                    value: 'date',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.planned_start_time'),
                    value: 'time',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.customer'),
                    value: 'clientName',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.site'),
                    value: 'locationName',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.location'),
                    value: 'pointName',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.template'),
                    value: 'templateName',
                    icon: false,
                    sortable: false
                },
                {
                    text: 'actions',
                    value: 'actions',
                    icon: false,
                    sortable: false
                }
            ];
        }
    },
    methods: {
        canEdit () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INSPECTION_SCHEDULE);
        },
        canRemove () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INSPECTION_SCHEDULE);
        },
        showEventClick (item) {
            this.$emit('show-event-click', item.id);
        },
        editEventClick (item) {
            this.$emit('edit-event-click', item.id);
        },
        removeEventClick (id) {
            this.$emit('remove-event-click', id);
        },
        cycleLabel (cycle) {
            if (cycle === EVENT_CYCLES.YEARLY) {
                return this.$t('scheduling.rrules.year');
            } else if (cycle === EVENT_CYCLES.MONTHLY) {
                return this.$t('scheduling.rrules.month');
            } else if (cycle === EVENT_CYCLES.WEEKLY) {
                return this.$t('scheduling.rrules.week');
            } else if (cycle === EVENT_CYCLES.DAILY) {
                return this.$t('scheduling.rrules.day');
            } else if (cycle === EVENT_CYCLES.ONCE) {
                return this.$t('scheduling.rrules.once');
            } else {
                return cycle;
            }
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', this.pagination.page);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>
