<template>
    <div>
        <FiltersBar ref="filtersBar" :withSearch="false"></FiltersBar>
    </div>
</template>

<script>
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import filtering from '@/mixins/filtering';
import { mapState } from 'vuex';

export default {
    name: 'FilterEvents',
    components: { FiltersBar },
    data: () => ({
        oldFilter: {}
    }),
    mixins: [
        debouncedResponsibleEmployee,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        filtering
    ],
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customers'),
                    loading: true,
                    multiple: false,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.sites'),
                    loading: false,
                    multiple: false,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    referralCallback: this.referralSiteCallback,
                    searchCallback: this.debouncedGetSites,
                    searchOptions: {
                        reference: FILTERS.CUSTOMER_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.locations'),
                    loading: false,
                    multiple: false,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    searchOptions: {
                        reference: FILTERS.SITE_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.PERSON_ID,
                    label: this.$t('labels.responsible_person'),
                    loading: true,
                    multiple: false,
                    search: FILTERS_SEARCHING.EMPLOYEES,
                    lazy: this.getResponsibleEmployeesLazyLoading,
                    searchCallback: this.getResponsibleEmployees,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'responsibleEmployees'
                    }
                })
            ];
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            const filterNames = {
                'customer_id': 'client',
                'place_id': 'location',
                'point_id': 'point',
                'employee_id': 'employee'
            };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (this.filters[f] && this.filters[f].length > 0) {
                    params[filterNames[f]] = Array.isArray(this.filters[f]) ? this.filters[f].join(',') : this.filters[f];
                }
            });

            const filterChanged = JSON.stringify(this.oldFilter) !== JSON.stringify(params);
            if (filterChanged) {
                this.oldFilter = params;
                return params;
            } else {
                return false;
            }
        },
        filterChanged () {
            const filter = this.generateFilters();
            if (filter) {
                this.$emit('filter-change', filter);
            }
        }
    },
    mounted () {
        this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
        this.setFiltersWatcher(this.filterChanged);

        this.$store.dispatch('employees/getResponsibleEmployees')
            .then(() => {
                this.filtersConfig.find(el => el.model === FILTERS.PERSON_ID).loading = false;
                this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
            });
        this.$store.dispatch('customers/getCustomers')
            .then(() => {
                this.filtersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
            });
    },
    beforeUnmount () {
        this.$store.dispatch('filters/clearFiltersParams');
        this.debouncedGetCustomers = null;
        this.debouncedGetLocations = null;
        this.debouncedGetSites = null;
        this.debouncedGetResponsibleEmployees = null;
    }
};
</script>

<style scoped lang="scss">
</style>
