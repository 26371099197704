<template>
    <Modal :modal-title="title"
           :dialog="manageEventFormVisibility"
           max-width="1000px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageEventForm ref="form"
                             :is-editable="isEdit"
                             :chosen-date="chosenDate"></ManageEventForm>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <ValidationError v-if="showError"></ValidationError>
            <span data-test="testKeydownSpan"></span>
        </template>

        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ManageEventForm from '@/components/scheduling/manageEvent/ManageEventForm';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageEvent',
    components: { Modal, ConfirmationDialog, ManageEventForm, ValidationError },
    props: {
        chosenDate: {
            type: [Date, String],
            'default': ''
        }
    },
    data: () => ({
        loader: false,
        showConfirmationDialog: false,
        showError: false
    }),
    computed: {
        ...mapState('scheduling', [
            'manageEventFormVisibility',
            'editedEventId'
        ]),
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEdit () {
            return this.editedEventId !== null;
        },
        title () {
            return this.isEdit ? this.$t('scheduling.edit_title') : this.$t('scheduling.add_title');
        }
    },
    methods: {
        close () {
            this.$store.commit('scheduling/SET_MANAGE_EVENT_FORM_VISIBILITY', false);
            this.$store.commit('scheduling/RESET_EVENT_DATA');
            this.$refs.form.resetValidation();
        },
        visibility (event) {
            this.$store.commit('scheduling/SET_MANAGE_EVENT_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createEvent () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('scheduling/createEvent')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateEvent () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('scheduling/updateEvent')
                            .then(() => {
                                this.$store.dispatch('scheduling/getActiveEvent', this.editedEventId);
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEdit) {
                this.updateEvent();
            } else {
                this.createEvent();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
